<template>
	<div v-if="obj.show" class="f1 relative">
        <a-form class="section-editor-container bg-white py-4" style="position:absolute; top:0;left:0;right:0;bottom:0;overflow:hidden;">
			<div class="mt-3" style="position: absolute; padding-bottom: 100px; inset: 0px; overflow: hidden scroll;">
				<template v-for="(item,itemI) in itemSettings">
					<div v-if="item.type === 'header'" :key="`section-${obj.item.id}-item-${itemI}`" class="section-editor-grouped" :class="item.children && item.children.length ? 'section-editor-accordion-wrapper' : ''">
						<h6 v-if="!item.children" v-text="item.id"/>
						<a-collapse :defaultActiveKey="itemI === 0 ? '1' : '0'" v-else-if="item.children.length" expandIconPosition="right" style="border:0;">
							<a-collapse-panel key="1" :header="item.label" :style="'background: #f7f7f7;border-radius: 4px;;overflow: hidden;border:0;'">

								<template v-for="(childItem,childItemI) in item.children">
									<template v-if="isShown[childItem.id]">
										<a-form-item class="relative w-full" :key="`section-${obj.item.id}-item-${childItemI}${childItem.id}`" :help="showHelp(childItem,obj.item.data[childItem.id])">
											<span v-if="childItem.label" slot="label">
												{{childItem.label || childItem.id}}
												<a-tooltip overlayClassName="change-tooltip-color" v-if="childItem.helpInfo && childItem.helpInfo.length > 0" :title="childItem.helpInfo">
													<a-icon type="question-circle-o" style="color: var(--orange);" />
												</a-tooltip>
											</span>
											<SectionEditItem :font-list="fontList" :key="`section-${obj.item.id}-item-${childItemI}${childItem.id}`" :data="obj.item.data" :setting="childItem"/>
										</a-form-item>
									</template>
								</template>

							</a-collapse-panel>
						</a-collapse>

					</div>
					<template v-else-if="isShown[item.id]">
						<a-form-item :label="item.label" :key="`section-${obj.item.id}-item-${itemI}`"  :help="showHelp(item)" class="px-3">
							<SectionEditItem :font-list="fontList"  :data="obj.item.data" :setting="item"/>
						</a-form-item>
					</template>
				</template>
				<a-collapse :defaultActiveKey="itemSettings.length ? '100' : '1'" expandIconPosition="right" v-if="obj.item.blocks && obj.item.blocks.blocks && obj.item.blocks.blocks.length">
					<a-collapse-panel key="1" header="Blocks" :style="'background: #f7f7f7;border-radius: 4px;;overflow: hidden;border:0;'">
						<BlocksEditor  :font-list="fontList"  :item="obj.item" />
					</a-collapse-panel>
				</a-collapse>
				<slot />
			</div>
        </a-form>
	</div>
</template>

<script>
import SectionEditItem from '@/components/site/SectionEditItem'
import BlocksEditor from '@/components/site/BlocksEditor'

export default {
    props:['obj','fontList'],
    components:{
        SectionEditItem,
        BlocksEditor
    },
    computed:{
		instance() {
			return this.$store.state.instance
		},

        values(){
            let values = {}
            this.itemSettings.forEach( item => {
                values[item.id] = this.obj.item.data[item.id]

                if (item.children && item.children.length){
                    item.children.forEach(childItem => {
                        values[childItem.id] = this.obj.item.data[childItem.id]
                    })
                }
            })
            return values
        },
        isShown(){
            let isShown = {}
            this.itemSettings.forEach( item => {
                if (!item.id) return
                if (item.type === 'header' && !item.children) return
                else if (item.children && item.children.length){
                    item.children.forEach(childItem => {
                        let is = true
                        if (childItem.hasOwnProperty('onlyIf')){
                            let checkFor = true
                            let keyToCheck = childItem.onlyIf
                            if (keyToCheck.indexOf('!') === 0){
                                checkFor = false
                                keyToCheck = keyToCheck.substr(1)
                            }
                            is = Boolean(this.values[childItem.onlyIf]) === checkFor
                        }
                        isShown[childItem.id] = is
                    })
                }
                else isShown[item.id] = !item.onlyIf ?  true : Boolean(this.values[item.onlyIf])
            })
            return isShown
        },
		itemSettings() {
			let objSettings = JSON.parse(JSON.stringify((this.obj?.item?.settings || {})))
			if (!this.$store.getters.isPremiumPackage) {
				objSettings = objSettings.filter(s => s.id !== 'reservationSettings')
			}

			return objSettings
		}
    },
    methods:{
        showHelp(item,val){
            if (!val) return item.help || ''
            if (item.type === 'linkText') {
                if (val.indexOf('http') !== 0){
                    return 'Note: your link does not seem valid'
                } else if (!item.help){
                    return 'Enter a valid Link'
                }
            }
            if (item.help) return item.help
            return ''
        },
    },
    watch:{
        obj:{
            deep:true,
            handler(val){
                window.selected = val.item
                window.change = (t) => val.item.template = t

            }
        }
    },
}
</script>

<style>

</style>
