<template>
    <div class="builder-screen">
        <bh-loading :show="loading" />
        <div style="width: 100vw; height: 100vh" class="dF fC">
            <TopBarTemplate
                :size="frameSize"
                :mode="previewMode? 'preview' : 'none'"
                :url="project.url"
                :currentlySaving="currentlySaving"
                :title="project.name"
				:currentPage="currentPage"
                @close="exit"
                @save="save"
                @preview="preview"
                @publish="publish"
            />

            <div class="w-full dF f1 bg-white">
                <div class="f1 relative builder-screen-wrapper" :style="$p === 10 ? 'cursor:not-allowed' : ''">
                    <div
                        class="iframe-holder"
						:style="$p === 10 ? 'pointer-events: none' : ''"
                        :class="
                        frameSize.current === 'mobile'
                            ? 'phone phone-iframe-container'
                            : ''
                        "
                    >
                        <iframe class="builder-iframe" src="" />
                    </div>
                </div>
                <SideToolbar
                    :event="windowMsg"
                    :project="project"
                    :page="currentPage"
                    @page-change="() => {}"
                    :selected-section="selectedSection"
                    :hide="previewMode"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { sections } from "@/assets/sections";
import { setProp } from "bh-mod";
import SideToolbar from "@/components/common/SideToolbar";
import bhLoading from "bh-mod/components/common/Loading";
import TopBarTemplate from "@/components/site/TopBarTemplate";
import axios from "axios";

function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }


    //New Code
    return new Blob([ab], {type: mimeString});


}

export default {
    provide () {

        return {
            addPageToProject: this.addPageToProject,
            pageGotEdited: this.pageGotEdited,
            save:this.save,
            load:this.load,
            updateFrame:this.updateFrame,
            project:this.getProject,
            saveCurrentPage:this.saveCurrentPage,
            pagesChanged:this.pagesChanged,
        }
    },
    components: {
        bhLoading,
        TopBarTemplate,
        SideToolbar,
    },
    data() {
        return {
            currentlySaving:false,
            projectSettingsUpdated:true,
            pagesChanged:[],

            timerId:undefined,
            selectedSection: "",
            previewMode:false,
            frameLoaded: false,
            sections,
            project: {},
            windowMsg: {},
            buttonTool: {
                show: false,
                configure: {},
            },
            frameSize: {
                current: "desktop",
            },
            linkTool: {
                show: false,
                configure: {
                link: "",
                linkClass: "",
                },
            },
            SRC: "",
            recentColorLookup2: {},
            recentColors: [],
            colorPicker: {
                show: false,
                callback: null,
                path: [],
            },
            widgetSetting: {
                show: false,
                type: "",
                configure: {},
            },
            widgetStyle: {
                show: false,
                type: "",
                configure: {
                bg: "#fff",
                titleColor: "#F00",
                titleHover: "#FF0",
                textColor: "#00F",
                textHover: "#F0F",
                },
            },
            navMenus: {},
            pages: [],
            IFRAME: null,
            previewing: false,
            previewFields: [],
            leadScoreShow: false,
            customFields: {},
            showAddCustomField: false,
            deleting: false,
            deletePopup: false,
            loading: false,
            template: {
                name: "",
                url: "",
                instance: "",
                published: false,
            },
            tags: [],
            exitAfter:false
        };
    },
    watch: {
		update(val){
			this.debounceRefresh('currentPage Sections')
			this.pageGotEdited()
		},
        reorderSection(){
            this.pageGotEdited()
            this.debounceRefresh('currentPage Sections')
        },
        'currentPage.isHeader'(){
            this.debounceRefresh('currentPage.isHeader')
            if (!this.$store.state.appData.builder.live){
                this.pageGotEdited()
            }

        },
        'currentPage.isFooter'(){
            this.debounceRefresh('currentPage.isFooter')
            if (!this.$store.state.appData.builder.live){
                this.pageGotEdited()
            }
        },
        previewModel: {
            deep: true,
            handler(){this.debounceRefresh('Model Changed')}
        },
        currentSectionsLength: {
            handler(){
                this.pageGotEdited()
                this.debounceRefresh('Section Added/removed')
            }
        },
        "project.fonts": {
            deep: true,
            handler(){this.debounceRefresh('project.fonts')}
        },
        "project.menu": {
            deep: true,
            handler(){this.debounceRefresh('project.fonts')}
        },
        "project.colors": {
            deep: true,
            handler(){this.debounceRefresh('project.colors')}
        },
        "project.data": {
            deep: true,
            handler(){this.debounceRefresh('project.data')}
        },
        currentSection:{
            deep:true,
            handler(val,oldVal){
                if (!val || !oldVal) return
                this.debounceRefresh('currentPage Sections')
                this.pageGotEdited()

            }
        },
        'currentPage.id': {
            handler(val,oldVal){
                this.debounceRefresh('currentPage ID')
            }
        },
        '$store.state.appData.builder.currPageId'(val){
            this.$store.commit('SET_PROP', {where:['builder','currentSection'],what:''})
            this.$store.commit('SET_PROP', {where:['builder','top'],what:0})
        },
    },
    computed: {
		fontList(){
			let fonts = this.$store.state.appData.fontList
			let obj = {}
			fonts.forEach(item => {

				let boldWeights = []

				let hasBold = false
				let boldVariant = 0

				let italicWeights = []
				let hasItalics = false
				let italicVariant = 0

				let boldItalicWeights = []
				let hasBoldItalics = false
				let boldItalicVariant = 0



				item.weights = item.variants.map( wght => {

					if (wght === 'regular') return '400'
					else if (wght === 'italic') return '400i'

					if (wght.indexOf('italic') > 0) return wght.replace('italic','i')

				})
				item.weights.forEach( weight => {

					let w = parseInt(weight)
					if (isNaN(w)) return

					if (weight.includes('i')){
						hasItalics = true
						italicWeights.push(w)
						if (w > 250 && w < 600){
							hasItalics = true
							if (w === 400){

							}

						} else if (w > 600) {

						}

					} else if (w > 650) {

						if (w == 700) {

							hasBold = true
							boldVariant = 700

						} else if (w == 800) {

							hasBold = true
							boldVariant = 800

						} else if (w == 900) {

							hasBold = true
							boldVariant = 900

						}

					}

				})


				item.category = item.category === 'handwriting' ? 'cursive' : item.category
				item.hasBold = hasBold
				item.boldVariant = boldVariant
				obj[item.family] = item

			})
			return obj
		},
		update(){
			return this.$store.state.appData.updateFrame
		},
        siteURL() {
            return this.$store.state.appData.siteURL;
        },
        reorderSection(){
            return this.$store.state.appData.reorderSection
        },
        needsSaving(){
            return this.pagesChanged.length && this.projectSettingsUpdated
        },
        previewModel(){
            return this.$store.state.appData.builder.previewModel
        },
        currentSectionsLength(){
            return this.currentSections.sections.length
        },
        currentSections(){

            let sections = []

            if (this.project.headers && this.project.headers.length){
                sections = [...sections,...this.project.headers]
            }
            if (this.currentPage.header && this.currentPage.header.id){
                sections = [...sections,this.currentPage.header]
            }
            if (this.currentPage && this.currentPage.sections && this.currentPage.sections.length){
                sections = [...sections, ...this.currentPage.sections]
            }

            if (this.project.footers && this.project.footers.length){
                sections = [...sections,...this.project.footers]
            }
            if (this.currentPage.footer && this.currentPage.footer.id){
                sections = [...sections,this.currentPage.footer]
            }

            return {
                isHeader:this.currentPage.isHeader,isFooter:this.currentPage.footer,sections
            }
        },
        currentSection(){
            let page = this.currentPage
            let secId = this.$store.state.appData.builder.currentSection

            if (!secId) return null

            let sections  = page.sections

            let currentSection = sections.find(x => x.id === secId)

            if (page.header && page.header.id === secId) currentSection = page.header
            if (page.footer && page.footer.id === secId) currentSection = page.footer

            if (!currentSection && this.project.headers){
                currentSection = this.project.headers.find(x => x.id === secId)
            }
            if (!currentSection && this.project.footers){
                currentSection = this.project.footers.find(x => x.id === secId)
            }

            return currentSection

        },
        tempEmail() {
            return this.$store.state.appData.tempEmail;
        },
        currentPage() {
            if (!this.project || !this.project.pages) return {}
            let pageId = this.$store.state.appData.builder.currPageId
            let idx = this.project.pages.findIndex(x => x.id === pageId)
            if (idx === -1) idx = 0
			console.log('CURRENT PAGE', this.project.pages[idx])
            return this.project.pages[idx];

        },
        cson() {
            return this.$store.state.appData.cson;
        },
        ctnURL() {
            return this.$store.state.appData.ctnURL;
        },
        sitePreviewURL() {
            return this.$store.state.appData.sitePreviewURL;
        },
        viewPortWidth() {
            return this.$store.state.appData.dom.w;
        },
        recentColorLookup() {
            let type = this.widgetStyle.type;
            if (!type || !this.widgetStyle.show) return [];

            let lookup = {
                bg1: ["all"],
                color1: ["models", "navigation", "md-fp-details-1"],
                hover1: ["models", "navigation", "md-fp-details-1"],
                bg2: ["md-fp-details-1"],
                color2: ["models", "navigation", "md-fp-details-1"],
                hover2: ["models"],
                color3: ["models", "navigation"],
                hover3: ["models"],
            };

            let colorsEntries = Object.entries(lookup)
                .filter(([key, value]) => {
                return value.includes("all") || value.includes(type);
                })
                .map((x) => x[0]);

            let result = [];

            colorsEntries.forEach((clr) => {
                let color = this.widgetStyle.configure[clr];
                if (!result.includes(color)) result.push(color);
            });

            return result;
        },
        modelTags() {
            return this.tags.filter((x) => x.type === "models");
        },
        lotTags() {
            return this.tags.filter((x) => x.type === "lots");
        },
        instance() {
            return this.$store.state.instance;
        },
		brokerInstance(){
			return this.instance && this.instance.package && this.instance.package.itemCode == 'PA-BRKR'
		},
        dialog() {
            return this.$store.state.appData.templateDrawer;
        },
        pageReq() {
            return this.currentPage.slug;
        },
        pageTop() {
            return this.$store.state.appData.builder.top;
        },
        pageChanging(){
            return this.$store.state.appData.builder.pageChanging;
        }
    },
    methods: {
        pageGotEdited(id = this.currentPage.id){
            if (!this.pagesChanged.includes(id)) this.pagesChanged.push(id)
        },
        packagePage(page,isNew){
            page = JSON.parse(JSON.stringify(page))
            if (isNew && page.header && page.header.id.includes('add_')){
				page.header.custom = false;
				page.header.default = false;
                delete page.header.id
                delete page.header._id
            }
            if (isNew && page.footer && page.footer.id.includes('add_')){
				page.footer.custom = false;
				page.footer.default = false;
                delete page.footer.id
                delete page.footer._id
            }

            page.sections = page.sections.map( (sec,secI) => {
				sec.custom = false;
				sec.default = false;
                let {id,_id,...newSec} = sec
                if (isNew || sec.id && sec.id.includes('add_')) {
                    newSec.slug = Date.now() + secI
                    return newSec
                }
                return sec
            })

            return page
        },
        savePage(page){
            return new Promise((resolve,reject) => {
                if (!page) return reject('No page')
                let pass = true
                page = this.packagePage(page)
                this.$api.put(`/projects/:instance/${this.project.id}/page`, page)
                    .then( ({data}) => {
                    })
                    .catch( () => {
                        pass = false
                    })
                    .finally( () => {
                        return resolve(pass)
                    })
            })

        },
        async saveCurrentPage(){
            this.$store.commit('LOAD',true)
            await this.savePage(this.currentPage)
            this.$store.commit('LOAD',false)
        },
        getProject(){
            return this.project
        },
        debounceRefresh(msg){
            if (!this.$store.state.appData.builder.live) return

            const makeAPICall = async () => {
                this.$store.commit('SET_PROP',{where:['builder','live'],what:false})
                await this.updateFrame();
                this.$store.commit('SET_PROP',{where:['builder','live'],what:true})
            }

            if (this.timerId) clearTimeout(this.timerId);

            this.timerId = setTimeout(function () {
                makeAPICall();
                this.timerId = undefined;

            }, 500);
        },
        async load() {
			try {
				this.$store.commit('LOAD', true)

				let { data: project } = await this.$api.get(`/projects/:instance/${this.$route.params.id}`)

				if (!project || !project.id || !project.pages || !project.pages.length) return this.$message.error("Something happend while retrieving your project"), this.$store.commit('LOAD', false)

				if (!project.seo.image) project.seo.image = project.data.darkLogo
				if (!project.includes) project.includes = { js: '', css: '', head: '' }
				if (!project.data.terms || !project.data.terms.content) {
					project.data.terms = {
						title: 'Terms & Conditions',
						content: ''
					}
				}
				if (project.domain && project.domain.url) project.url = project.domain.url
				if (!project.data.privacy || !project.data.privacy.content) {
					project.data.privacy = {
						title: 'Privacy Policy',
						content: ''
					}
				}
				project.pages.sort(function (a, b) {
					if (a.name < b.name) { return -1; }
					if (a.name > b.name) { return 1; }
					return 0;
				})

				let home = null;
				let blog = null;
				let model = null;
				let login = null;
				project.pages.forEach((page, index) => {
					if (!page.seo) page.seo = { title: '', description: '', noIndex: false }
					if (!page.seo.noIndex) page.seo.noIndex = false
					if (page.slug == 'index') {
						home = JSON.parse(JSON.stringify(page))
					}
					if (page.slug == 'blogpost') {
						blog = JSON.parse(JSON.stringify(page))
					}
					if (page.slug == 'modeldetails') {
						model = JSON.parse(JSON.stringify(page))
					}
					if (page.slug == 'login') {
						login = JSON.parse(JSON.stringify(page))
					}
				})
				let p = project.pages
				p = p.filter(x => x.slug != 'index' && x.slug != 'blogpost' && x.slug != 'modeldetails' && x.slug != 'login')
				if (home != null && blog != null && model != null && login != null) {
					p = [home, blog, model, login, ...p]
					project.pages = p
				}

				let currPage = project.pages.find(x => x.isIndex)
				if (!currPage) currPage = project.pages[0]
				this.$store.commit('SET_PROP', { where: ['builder', 'currProjectId'], what: project.id })
				this.$store.commit('SET_PROP', { where: ['builder', 'currProject'], what: project })

				if (!this.$store.state.appData.builder.currPageId) {
					this.$store.commit('SET_PROP', { where: ['builder', 'currPageId'], what: currPage.id })
				}


				this.$store.commit('SET_PROP', { where: ['projects', project.id], what: project })
				this.project = project;

				this.$store.commit("LOAD", false);
			} catch (err) {
				this.$store.commit("LOAD", false);
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
		},
        async previewLanding(){
            let obj = {
                publish:false,
                datas:{_modelsData:this.modelsData, _siteplanData:this.siteplanData,_amenitiesData:this.amenitiesData,model:this.previewModel},
                iSlug:this.instance.slug,
                project: this.project,
                pageReq:this.pageReq
            }

			if (this.instance && this.instance.api && this.instance.api.key){
				obj.apiKey = this.instance.api.key
			}

            this.$store.commit('LOAD',true)
            let bildURL = `${this.ctnURL}/bildsite`
            axios.post(bildURL, obj).then( ({ data: saved }) => {

                if (saved && saved.id){

                    this.$store.commit('SET_PROP',{where:['builder','currProject'],what:this.project})
                    let url = saved.url
                    window.open(url)
                }

            }).finally( () => {
                this.$store.commit('LOAD',false)
            })


        },
        async previewSite(){

            this.$store.commit("LOAD", true);

            if (this.project.type != 'static' && !this.modelsData && !this.brokerInstance){
                let models = await fetch(`https://api.bildhive.${this.$tld}/v2/models?token=${this.instance.api && this.instance.api.key}&children=1`).then(x => x.json())
                this.modelsData = models
                this.$store.commit('SET_PROP', {where:['builder','datas','models'],what:models})
            }


            this.$store.commit('SET_PROP', {where:['builder','currPage'],what:this.currentPage})

            if (!document.querySelector("iframe.builder-iframe")) return

            let bildURL = `${this.ctnURL}/bildsite`

			let apiKey = ''
			if (this.instance && this.instance.api && this.instance.api.key){
				apiKey = this.instance.api.key
			}

            let { data: saved } = await axios.post(bildURL, {
                renderAll:true,
                datas:{
                    _modelsData:this.modelsData,
                    model:this.$store.getters.previewModel
                },
                iSlug:this.instance.slug,
                pageReq: this.pageReq,
                project: this.project,
				apiKey: apiKey
            })



            if (!saved.id || !saved.url){

                return this.$message.error(
                    "There was a problem while saving Web site preview"
                );
            }

            let slug = ''

            if (this.$store.getters.currPage && !this.$store.getters.currPage.isIndex){
                slug = this.$store.getters.currPage.slug
            }

            let theURL = `${saved.url}/${slug}`;
            this.$store.commit("LOAD", false);
            window.open(theURL)
        },
		async previewSiteLimited(){

			// this.$store.commit("LOAD", true);

			if (this.project.type != 'static' && !this.modelsData && !this.brokerInstance){
				let models = await fetch(`https://api.bildhive.${this.$tld}/v2/models?token=${this.instance.api && this.instance.api.key}&children=1`).then(x => x.json())
				this.modelsData = models
				this.$store.commit('SET_PROP', {where:['builder','datas','models'],what:models})
			}


			this.$store.commit('SET_PROP', {where:['builder','currPage'],what:this.currentPage})

			if (!document.querySelector("iframe.builder-iframe")) return

			let bildURL = `${this.ctnURL}/bildsite`

			let apiKey = ''
			if (this.instance && this.instance.api && this.instance.api.key){
				apiKey = this.instance.api.key
			}

			let { data: saved } = await axios.post(bildURL, {
				renderAll:true,
				datas:{
					_modelsData:this.modelsData,
					model:this.$store.getters.previewModel
				},
				iSlug:this.instance.slug,
				pageReq: this.pageReq,
				project: this.project,
				apiKey: apiKey
			})



			if (!saved.id || !saved.url){

				return this.$message.error(
					"There was a problem while saving Web site preview"
				);
			}

			let slug = ''

			if (this.$store.getters.currPage && !this.$store.getters.currPage.isIndex){
				slug = this.$store.getters.currPage.slug
			}

			let theURL = `${saved.url}/${slug}`;
			// this.$store.commit("LOAD", false);
			// window.open(theURL)
		},
        async exit(save = true){
            if (save) {
                this.exitAfter = true
                await this.save()
            }
            else {
                this.$router.push('/')
            }
        },
        preview(){
            if (this.$p === 10) {
                return this.$message.error('You do not have permission to update the preview');
            }
            if (this.project.type === 'microsite') return this.previewSite()
            return this.previewLanding()

        },
        publish(){
            if (this.$p < 40) {
                return this.$message.error('You do not have permission to publish this project')
            }
            if (this.project.type === 'microsite') return this.publishSite()
            return this.publishLanding()

        },

        async publishLanding(){
            let obj = {
                instanceSlug:this.instance.slug,
                publish:true,
                datas:{_modelsData:this.modelsData, _siteplanData:this.siteplanData,_amenitiesData:this.amenitiesData,model:this.previewModel},
                iSlug:this.instance.slug,
                project: this.project,
                pageReq:this.pageReq
            }

			if (this.instance && this.instance.api && this.instance.api.key){
				obj.apiKey = this.instance.api.key
			}

            this.$store.commit('LOAD',true)
            let bildURL = `${this.ctnURL}/bildsite`

            // if (location.href.includes('localhost')) bildURL = `http://localhost:3331/bildsite`
            axios.post(bildURL, obj).then( ({ data: saved }) => {

                if (saved && saved.id){

                    this.$store.commit('SET_PROP',{where:['builder','currProject'],what:this.project})
                    let url = saved.url
                    window.open(url)
                }

            }).finally( () => {
                this.$store.commit('LOAD',false)
            })


        },
        async publishSite(){

            this.$store.commit("LOAD", true);

			await this.previewSiteLimited()

            if (this.project.type != 'static' && !this.modelsData && !this.brokerInstance){
                let models = await fetch(`https://api.bildhive.${this.$tld}/v2/models?token=${this.instance.api && this.instance.api.key}&children=1`).then(x => x.json())
                this.modelsData = models
                this.$store.commit('SET_PROP', {where:['builder','datas','models'],what:models})
            }


            this.$store.commit('SET_PROP', {where:['builder','currPage'],what:this.currentPage})

            if (!document.querySelector("iframe.builder-iframe")) return

            let bildURL = `${this.ctnURL}/bildsite`

			let apiKey = ''
			if (this.instance && this.instance.api && this.instance.api.key){
				apiKey = this.instance.api.key
			}

            let { data: saved } = await axios.post(bildURL, {
                instanceSlug:this.instance.slug,
                publish:true,
                renderAll:true,
                datas:{
                    _modelsData:this.modelsData,
                    model:this.$store.getters.previewModel
                },
                iSlug:this.instance.slug,
                pageReq: this.pageReq,
                project: this.project,
				apiKey: apiKey
            })

            if (!saved.id || !saved.url){

                return this.$message.error(
                    "There was a problem while saving Web site preview"
                );
            }

            let slug = ''

            if (this.$store.getters.currPage && !this.$store.getters.currPage.isIndex){
                slug = this.$store.getters.currPage.slug
            }

            let theURL = `${saved.url}/${slug}`;
            this.$store.commit("LOAD", false);
            window.open(theURL)
        },

        packageProject(){
            let project = JSON.parse(JSON.stringify(this.project))

            let {pages} = project
            if (project.headers && project.headers.length){
                project.headers.forEach(header => {
                    if (header.id.includes('add_')){
						header.custom = false;
						header.default = false;
						delete header.id
						delete header._id
					}
                })
            }
            if (project.footers && project.footers.length){
                project.footers.forEach(footer => {
                    if (footer.id.includes('add_')){
						footer.custom = false;
						footer.default = false;
						delete footer.id
						delete footer._id
					}
                })
            }
            pages.forEach(page => {
                // if (page.id.indexOf('add_') === 0) delete page.id
                if (page.header && page.header.id.includes('add_')){
					page.header.custom = false;
					page.header.default = false;
					delete page.header.id
					delete page.header._id
				}
                if (page.footer && page.footer.id.includes('add_')){
					page.footer.custom = false;
					page.footer.default = false;
					delete page.footer.id
					delete page.footer._id
				}

                page.sections = page.sections.map( sec => {
						sec.custom = false;
						sec.default = false;
                        let {id,_id,...newSec} = sec
                        if (sec.id && sec.id.includes('add_')) return newSec
                        return sec
                })

            })
            return project
        },
        addPageToProject(page){
            return new Promise((resolve,reject) => {

                if (!page) return reject('No page')

                let pass = true

                page = this.packagePage(page,true)
                this.$api.put(`/projects/:instance/${this.project.id}/page`, page)
                    .then( async ({data:page}) => {
                        this.project.pages.push(page)
                        await this.updatedProject()
                        this.$message.success('Successfully created a page ' + page.name)
                    })
                    .catch( () => {
                        pass = false
                    })
                    .finally( () => {
                        return resolve(pass)
                    })
            })

        },
		async updatedProject(project = this.project) {
			return new Promise((resolve, reject) => {
				project = JSON.parse(JSON.stringify(project))
				project.pages = project.pages.map(page => {
					if (page.id) return page.id
					return page
				})

				this.$api.put(`/projects/:instance/${this.project.id}/update`, project)
					.then(() => { return resolve() })
					.catch((error) => { return reject(error) })
			})
		},
        handleUpload(fileObj){
            return new Promise( (resolve,reject) => {
                const formData = new FormData();
                formData.append('files', fileObj, `${this.project.id}.jpg`);
				formData.append('force', 1);

                this.$api.post('/upload',formData,{headers: { 'Content-Type': 'multipart/form-data' }}).then( ({data}) => {
                    if (Array.isArray(data) && data[0]){
                        resolve(data[0].url)
                    } else {
                        reject()
                    }
                }).catch( err => {
                    this.$message.error(this.$err(err))
                    reject()
                })
            })

        },
        async continueSaving(img){
            let project = this.packageProject()

            let message = () => {}

            let pageNum = 1
            let pagesChanged = JSON.parse(JSON.stringify(this.pagesChanged))
            this.pagesChanged = []
            for (const key in pagesChanged) {
                if (Object.hasOwnProperty.call(pagesChanged, key)) {
                    const pageId = pagesChanged[key];
                    let page = project.pages.find(page => page.id === pageId)
                    await this.savePage(page)
                    message()
                    message = this.$message.loading(`Saving ${pageNum} of${pagesChanged.length} page(s) `,0)
                    pageNum++

                }
            }

            let imgUrl = ''
            try {
                let file = dataURItoBlob(img)
                imgUrl = await this.handleUpload(file)
            } catch(err){
				console.error('error', err)
            }

            if (imgUrl){
                project.image = imgUrl
            }

			if (this.projectSettingsUpdated) {
				try {
					await this.updatedProject(project)
				} catch (err) {
					if (err?.response?.status !== 400) {
						return this.$message.error(this.$err(err, 'Error occurred while saving project. Please try again.'))
					}
				}
			}
			message()

			this.currentlySaving = false
			try {
				await this.$api.put(`/projects/:instance/${this.project.id}`, project)
			} catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
            this.$message.success('Project Saved')
            this.$store.commit('UPDATE_PROJECT', project)
            this.$store.commit('LOAD',false)

            if (this.exitAfter){
                this.exitAfter = false
                this.$router.push('/')
            }
        },
        async save() {

            if (this.$p === 10) {
                return this.$message.error('You do not have permission to update the preview');
            }

            this.currentlySaving = true

            document.querySelector("iframe").contentWindow.postMessage({
                action: "screenshot",
                for:'save',
            },this.siteURL);
        },
        async updateFrameSize() {
            if (this.frameLoaded && document.querySelector("iframe")) {
                let box = document.querySelector("iframe").getBoundingClientRect();
                let { width, height, left, right, top, bottom } = box;
                document
                .querySelector("iframe")
                .contentWindow.postMessage(
                    {
                    action: "setIframe",
                    value: { width, height, left, right, top, bottom },
                    },
                    this.siteURL
                );
            }
        },
        async updateSite() {
            return new Promise( async (resolve,reject) => {

                this.$store.commit("LOAD", true);


                if (this.project.type != 'static' && !this.modelsData && !this.brokerInstance){

                    let models = await fetch(`https://api.bildhive.${this.$tld}/v2/models?token=${this.instance.api && this.instance.api.key}&children=1`).then(x => x.json())

                    this.modelsData = models
                    this.$store.commit('SET_PROP', {where:['builder','datas','models'],what:models})
                }


                this.$store.commit('SET_PROP', {where:['builder','currPage'],what:this.currentPage})

                if (!document.querySelector("iframe.builder-iframe")) return;

                if (this.frameLoaded) {
                    document
                        .querySelector("iframe")
                        .contentWindow.postMessage({ action: "setTop" }, this.siteURL);

                    this.frameLoaded = false;
                }

				this.project.pages.forEach(x => {
					x.sections = x.sections.filter(y => y != null)
				})

                let { data: saved } = await axios.post(`${this.siteURL}/savesite`, {
                    renderAll:!Boolean(this.allRendered),
                    datas:{
                        _modelsData:this.modelsData,
                        model:this.$store.getters.previewModel
                    },
                    iSlug:this.instance.slug,
                    pageReq: this.pageReq,
                    project: this.project,
                })



                if (!this.allRendered) this.allRendered = true

                if (!saved.id){

                    return this.$message.error(
                        "There was a problem while saving Web site preview"
                    );
                }

                let currentSection = this.$store.state.appData.builder.currentSection

                let slug = ''
                if (this.$store.getters.currPage && !this.$store.getters.currPage.isIndex){
                    slug = `&slug=/${this.$store.getters.currPage.slug}`
                }

                let frameSrc = `${this.siteURL}/tmp/${saved.id}?ver=${Date.now()}&builder=true${slug}`;
                if (currentSection) frameSrc += `&sec=${currentSection}`
                else if (this.pageTop) frameSrc += `&top=${this.pageTop}`;

				if (!document.querySelector("iframe.builder-iframe")) return reject();

                document.querySelector("iframe.builder-iframe").src = frameSrc;
                this.$store.commit("LOAD", false);

                this.$store.commit('SET_PROP',{where:['builder','pageChanging'],what:false})

                return resolve()
            })

        },
        updateFrame(id) {
			console.log('UPDATE FRAME RAN')
            if (id && this.currentPage.id !== id) return

            if (this.project.type === 'microsite') return this.updateSite()

            return new Promise( async (resolve,reject) => {

                this.$store.commit("LOAD", true);

                if (!document.querySelector("iframe.builder-iframe")) return reject();



                if (this.project.type != 'static' && !this.modelsData && !this.brokerInstance){

                    let models = await fetch(`https://api.bildhive.${this.$tld}/v2/models?token=${this.instance.api && this.instance.api.key}&children=1`).then(x => x.json())
                    this.modelsData = models
                    this.$store.commit('SET_PROP', {where:['datas','models'],what:models})

                }

                if (this.frameLoaded) {
                    document
                    .querySelector("iframe")
                    .contentWindow.postMessage({ action: "setTop" }, this.siteURL);
                    this.frameLoaded = false;
                }

				let foundPage = this.project.pages.find(x => x.id === this.currentPage.id)

                let { data: saved } = await axios.post(`${this.siteURL}/savetmp`, {
                    datas:{
                        _modelsData:this.modelsData,
                    },
                    iSlug:this.instance.slug,
                    pageReq: foundPage.slug,
                    project: this.project,
                })

                if (!saved.id){
                    return console.error("There was a problem while saving landing page preview"),reject();
                }

                let currentSection = this.$store.state.appData.builder.currentSection

                let frameSrc = `${this.siteURL}/tmp/${saved.id}?ver=${Date.now()}&builder=true`;
                if (currentSection) frameSrc += `&sec=${currentSection}`
                else if (this.pageTop && this.pageChanging) frameSrc += `&top=${this.pageTop}`;

                if (!document.querySelector("iframe.builder-iframe")) return reject();

                document.querySelector("iframe.builder-iframe").src = frameSrc;

                this.$store.commit("LOAD", false);
                this.$store.commit('SET_PROP',{where:['builder','pageChanging'],what:false})
                return resolve()
            })

        },

        nextStep() {
            this.continue();
        },
        resetColor() {
            this.colorPicker = {
                show: false,
                path: [],
                callback: null,
            };
        },
        colorCallBack(color) {
            setProp(this, this.colorPicker.path, color.hex);
            this.resetColor();
        },
        chooseColor(path) {
            this.colorPicker = {
                show: true,
                path,
                callback: this.colorCallBack,
            };
        },
        updateMenu(menus) {
            this.navMenus = menus;
        },
        async saveRecentColors(clrs) {
			try {
				let { data } = await this.$api.put(`/websites/${this.instance.id}/${this.site.id}`, { cson });
				if (!data.id)
					return this.$message.error("Something went wrong. Please Try Again.");
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
		},
        reset() {
            this.frameSize = { current: "desktop" };
        },
        close() {
            this.reset();
            this.$store.commit("SET_PROP", {
                where: "templateDrawer",
                what: {
                show: false,
                type: "add",
                site: "",
                data: {},
                },
            });
        },
		generateFont(){

			if (this.fontList && Object.values(this.fontList).length) return

			axios.get('https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyC5XCJYbRRAbcAZLdIp7OAY232lc96HiS8').then( ({data}) => {
				this.$store.commit('SET_PROP', {where:['fontList'],what:data.items})
			})

		},
    },
    async created() {
        await this.load()
		this.generateFont()
        window.onmessage = (e) => {
            if (e && e.data &&
                e.data.source &&
                typeof e.data.source === "string" &&
                e.data.source.includes("vue")
            )
                return null;
            if (typeof e.data.type === "string" && e.data.type.includes("webpack"))
                return null;

            if (!e.data.action) return;

            let evt = e.data;
            this.windowMsg = evt;

            if (evt.action === "frameLoaded") {

                this.frameLoaded = true;

            } else if (evt.action === "selectedWidget") {

                this.$store.commit('SET_PROP',
                    {
                        where:['builder','currentSection'],
                        what:evt.value
                    }
                )

            } else if (evt.action === "setTop") {
                this.$store.commit("SET_PROP", {
                    where: ["builder", "top"],
                    what: evt.value,
                });
            } else if (evt.action.includes("section")) {
                if (evt.action === "sectionEdit" && evt.id) {
                this.selectedSection = evt.id;
                }
            } else if (evt.action === "appendSection" && evt.id) {
                this.prevSection = evt.id;
            } else if (evt.action === "screencaptured" && evt.for) {

                this.continueSaving(evt.value)

            }
        }

        window.onresize = (e) => {
            this.updateFrameSize();
        }

        this.updateFrameSize()

    },
    beforeDestroy(){
        this.$store.commit('SET_PROP',{
            where:['builder'],
            what:{
                top:0,
                currentSection:'',
                currPageId:'',
                richVariables:false,
                variables:[],
                landing:false,
                currProject:{},
                currProjectId:'',
                pageChanging:false,
                live:true,
                previewModel:'lorem',
            }
        })
    }
};
</script>
<style lang="scss" scoped>
.field-box {
  border: 1px solid var(--light-gray);
}
[class*="field-box-"] {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  transition: color 0.3s ease-out;
  &:hover {
    color: var(--light-purple);
  }
}
.field-box-edit {
  right: 30px;
  &:hover {
    color: var(--orange);
  }
}
.preview-form .ant-card-head {
  background: var(--off-white-dark);
}
.builder-screen {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  transform: translate3d(0, 50px, 0);
  animation: builder-in 0.3s ease-out;
  animation-fill-mode: forwards;
}
@keyframes builder-in {
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
</style>


<style lang="scss">
.widget-settings {
  [role="tab"] {
    padding-top: 0;
  }
}
.builder-screen-wrapper {
  background: #efefef;
  overflow: hidden;
}
.builder-iframe {
  box-shadow: 1px 3px 5px rgba(100, 100, 100, 0.3);
  width: 100%;
  height: 100%;
}
.phone {
  position: absolute;
  top: 120px;
  > iframe {
    position: relative;
    width: 269px;
    height: 480px;
    top: 50px;
    left: 14px;
    background: #fff;
    z-index: 999;
  }
}
.iframe-holder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
  height: 100%;
}

.phone-iframe-container {
  width: 300px;
  height: 594px;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/121761/Nexus_5_Mockup_300px.png");
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 55;
  position: absolute;
}
</style>
