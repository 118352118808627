<template>
  <div class="builder_section_modal-container relative" :class="dialog.show ? 'show' : ''">

    <div class="builder_section_modal-overlay" @click="close" />

    <div class="builder_section_modal relative">
        <BHLoading :show="loading" style="background:none !important" />
        <div class="builder_section_modal-wrapper" v-if="sections && sections.length">
            <div class="dF jSB px-3" >
                <h4 class="mb-0 pl-3" v-if="dialog.restriction === 'none'">{{dialog.replace? 'Replace' : 'Add'}} Section</h4>
                <h4 class="mb-0 pl-3" v-else style="text-transform:capitalize">{{dialog.replace? 'Replace' : 'Add'}} {{dialog.restriction}}</h4>
                <a-icon @click="close()" type="close" />
            </div>
            <div class="builder_section-content-wrapper hide-scrollbar">
                <div class="scroll-y hide-scrollbar">
                    <ul class="section_cat-container pt-5">
                        <li class="section_cat-item" :class="filter === 'all' ? 'active' : ''" @click="filter='all'" >All Sections</li>
                        <li class="section_cat-item" :class="filter === '%%custom%%' ? 'active' : ''" @click="filter='%%custom%%'" >Saved Sections</li>
                        <li class="section_cat-item" :class="filter === cat.label ? 'active' : ''" @click="filter=cat.label" v-for="cat in sectionCategorized" :key="cat.label">{{cat.label}}</li>
                        <li v-if="sectionTransformed.uncat.length" class="section_cat-item" :class="filter === '%%uncat%%' ? 'active' : ''" @click="filter='%%uncat%%'" >Uncategorized</li>
                    </ul>
                </div>
                <div class="builder_section-grid-wrapper hide-scrollbar">
                    <div class="builder_section-grid pt-5" v-if="sectionsFiltered.length">
						<div class="relative" v-for="sec in sectionsFiltered" :key="sec.id" >
							<a-icon v-if="sec.custom" @click="deleteCustomSection(sec)" class="absolute" style="top: -12px; right: -12px; z-index: 100; font-size: 25px; color: var(--danger);" type="close-circle" />
							<div :title="sec.name" @click="selectSection(sec)" class="builder_section-grid-item" :style="`background-image: url(https://res.bildhive.${tld}${sec.image ? sec.image : '/thumbnails/noimage.jpg'})`" />
						</div>
                    </div>
                    <div class="dF jC aC h-full" v-else>
                        <div class="text-center" v-if="filter == '%%custom%%'">
                            <a-icon style="font-size:30px;" type="info-circle" />
                            <p class=mt-2>No Saved Sections Found</p>
                        </div>
                        <div v-else  class="text-center">
                            <a-icon style="font-size:30px;" type="info-circle" />
                            <p class=mt-2>No Sections Found</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import axios from 'axios'
export default {
    props:['project'],
    components:{BHLoading},
    data:() => ({
        loading:false,
        filter:'all',
        tld:window.location.href.includes('bildhive.com')? 'com' : 'dev'
    }),
    watch:{
        'dialog.init':{
            async handler(val){

                if (!val) return
                if (val) {
					this.loading = true
					try {
						let { data } = await axios.post(`${this.$store.state.appData.siteURL}/list/sections`)
						this.$store.commit('SET_SECTIONS', data)
					} catch (err) {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					}
					this.loading = false
				}
            }
        },
        'dialog.show':{
            async handler(val){
                if (val){
                    this.filter = 'all'
					try {
						let {data} = await this.$api.get(`/sections/:instance?custom=true&type=web`)
						this.$store.commit('SET_SECTIONS', data)
					} catch (err) {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					}
                }
            }
        },
        sections(val){
            if (val && val.length){

            }
        }
    },
    computed:{
        currentPage(){
            console.log('CURRENT PROJECT FROM SECTION MODAL', this.project)
            let currPage = this.$store.state.appData.builder.currPageId
            let page = this.project.pages && this.project.pages.find(x => x.id == currPage)
            return page && page.name
        },
        dialog(){
            return this.$store.state.appData.sectionModal
        },
        sections(){
            let sections = Object.values(this.$store.state.appData.sections).filter(x => {
				if (this.brokerInstance){
					if (x.category == 'Home Model' || x.category == 'Siteplan' || x.category == 'Model Details' || x.category == 'Condo' || x.category == 'Amenities' || x.category == 'Features') return false
					else return true && x.restriction === this.dialog.restriction
				} else {
					if (this.project.type == 'static'){
						if (x.category == 'Home Model' || x.category == 'Siteplan' || x.category == 'Blog' || x.category == 'Model Details' || x.category == 'Condo' || x.category == 'Blog Details' || x.category == 'Brokers') return false
						else return true && x.restriction === this.dialog.restriction
					} else {
						if (this.productType == 'lowrise'){
							if (this.currentPage == 'Blog Details'){
								if (x.category == 'Model Details' || x.category == 'Condo' || x.category == 'Brokers') return false
								else return true && x.restriction === this.dialog.restriction
							} else if (this.currentPage == 'Model Details'){
								if (x.category == 'Blog Details' || x.category == 'Condo' || x.category == 'Brokers') return false
								else return true && x.restriction === this.dialog.restriction
							} else {
								if (x.category == 'Model Details' || x.category == 'Blog Details' || x.category == 'Condo' || x.category == 'Brokers') return false
								else return true && x.restriction === this.dialog.restriction
							}
						} else {
							if (this.currentPage == 'Blog Details'){
								if (x.category == 'Model Details' || x.category == 'Home Model' || x.category == 'Single Model' || x.category == 'Brokers') return false
								else return true && x.restriction === this.dialog.restriction
							} else {
								if (x.category == 'Model Details' || x.category == 'Blog Details' || x.category == 'Home Model' || x.category == 'Single Model' || x.category == 'Brokers') return false
								else return true && x.restriction === this.dialog.restriction
							}
						}
					}
				}
            }).sort((a,b) => {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            })
            return sections
        },
        productType(){
            return this.$store.state.instance.productType
        },
        instanceId(){
            return this.$store.state.instance.id
        },
		instance(){
			return this.$store.state.instance
		},
		brokerInstance(){
			return this.instance && this.instance.package && this.instance.package.itemCode == 'PA-BRKR'
		},
        sectionCategorized(){
            console.log('SECTION CATEGORIZED', this.sectionTransformed.cats && Object.values(this.sectionTransformed.cats))
            let cats = this.sectionTransformed.cats && Object.values(this.sectionTransformed.cats) || []
            cats.sort((a,b) => {
                var textA = a.label.toLowerCase();
                var textB = b.label.toLowerCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            })
			if (!this.$store.getters.isPremiumPackage) {
				return cats.filter(c => c.label !== 'Reservations')
			}
            return cats
        },
        customSections(){
            return this.sectionTransformed.custom || []
        },
        sectionTransformed(){
            let cats = {}
            let custom = []
            let uncat = []

            this.sections.forEach(sec => {

                if (sec.instance === this.instanceId) return custom.push(sec)
                if (!sec.category) return uncat.push(sec)

                let cat = sec.category
                if (!cats[cat]) cats[cat] = {label:cat, sections:[]}
                cats[cat].sections.push(sec)
            })

            return {
                cats,
                custom,
                uncat
            }
        },
        sectionsFiltered(){
            if (!this.filter || this.filter === 'all') return this.sections
            if (this.filter === '%%custom%%') return this.customSections
            if (this.filter === '%%uncat%%') return this.sectionTransformed.uncat
            let result =  this.sectionTransformed.cats[this.filter] || {sections:[]}
            return result.sections
        }
    },
    methods:{
		deleteCustomSection(section){
			console.log('Deleting custom section...', section)
			this.$api.delete(`/sections/:instance/${section.id}`).then(({data}) => {
				console.log('Deleted custom section', data)
				this.$store.commit('SET_PROP',{where:['sections',data.id],del:true})
				this.$message.success('Saved section deleted!')
			}).catch(err => {
				console.log('Error deleting custom section', err)
			})
		},
        selectSection(section){
            console.log('SELECTED SECTION', section)
            this.dialog.cb && this.dialog.cb(JSON.parse(JSON.stringify(section)))
            this.close()
        },
        close(){
            console.log('CLOSE RAN')
            this.$store.commit('SET_PROP',{where:'sectionModal',what:{show:false,cb:null,init:true,restriction:'none',replace:false}})
        },
    },
    async created(){

    }
}
</script>

<style lang="scss">


    .builder_section_modal-overlay{
        position: absolute;
        left:0;
        bottom:0;
        top:0;
        right:0;
        background:rgba(100,100,100,0.4);
        z-index:100;

    }
    .builder_section_modal{
        position: absolute;
        z-index:101;
        max-width:calc(100% - 20px);
        max-height:calc(100% - 20px);
        top:50%;
        left:50%;
        transform: translate3d(-50%,-50%,0) scale(0.7);
        width:1200px;
        height:700px;
        background:white;
        box-shadow: 0px 2px 10px rgba(100,100,100,0.4);
        border-radius: 10px;
        transition:opacity .3s ease-out, transform .3s ease-out;

    }

   .builder_section_modal-container{
        position: fixed;
        left:0;
        top:0;
        width:100vw;
        height:100vh;
        z-index:100;
        &:not(.show){
            pointer-events: none;
            .builder_section_modal,.builder_section_modal-overlay{
                opacity:0;
                pointer-events: none;
            }
        }
        &.show{
            .builder_section_modal{
                // animation: scale-up .3s ease-out;
                // animation-fill-mode: forwards;
                transform: translate3d(-50%,-50%,0) scale(1);
                opacity:1;
            }
        }
    }

    @keyframes scale-up{
        to{
            transform: translate3d(-50%,-50%,0) scale(1);
        }
    }

    .builder_section_modal-wrapper{
        position: absolute;
        left:0;
        top:20px;
        width:100%;
        height:calc(100% - 60px);
        margin:0;
        overflow:hidden;
        display:flex;
        flex-direction: column;


    }
    .builder_section-content-wrapper{
        flex:1;
        display:grid;
        grid-template-columns: 150px 1fr;
        overflow-y:scroll;
    }
    .builder_section-grid-wrapper{
        position: relative;
        overflow-y:scroll;
    }
    .builder_section-grid{
        position: absolute;
        left:0;
        top:0;
        right:0;
        bottom:0;
        display:flex;
        flex-direction: column;
        gap:20px;
        padding:20px 20px 50px;
        @media screen and (min-width:900px){
            display:grid;
            padding:0 40px 40px;
            gap:40px;
            grid-template-columns: minmax(100px,1fr) minmax(100px,1fr);
        }
    }
    .builder_section-grid-item{
        height:0;
        padding-bottom:50%;
        background-size:95%;
        background-color:rgba(240,240,240,0.8);
        background-repeat:no-repeat;
        background-position: center;
        border-radius:4px;
        border:1px solid var(--light-purple);
        cursor: pointer;
        transition:transform .3s ease-out, box-shadow .3s ease-out;
        box-shadow:0 0px 1px rgba(100,100,100,0.1);
        &:hover{
            transform:translate3d(0,-1px,0);
        }
    }
    .section_cat-container{
        list-style:none;
        margin:0;
        padding:0 0 0 30px;

    }
    .section_cat-item{
        padding:5px 0;
        cursor:pointer;
        &:hover,&.active{
            color:var(--primary);
        }
    }
    .scroll-y{
        overflow-y:scroll;
    }

</style>
